import styled, { css } from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "../../../../constants/styleConstants";
import { FONT, FONT_SIZE, FONT_WEIGTH } from "../../../../constants/fontConstants";
import { Link } from "react-router-dom";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 100px;
    align-items: flex-start;

    height: fit-content;

    justify-self: end;

    gap: 32px;

    @media (max-width: 768px) {
        position: relative;
        width: 100%;
        justify-self: unset;
        padding: 0px;
    }

`;

export const ContainerCustomStay = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    width:calc(454px - 32px);

    padding: 16px;

    row-gap: 16px;

    background-color: ${NEUTRAL_COLORS.WHITE};

    border-radius: 16px;
    box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.1);


    @media (max-width: 768px) {
        position: relative;
        border-radius: none;
        box-shadow: none;
        width: 100%;
        padding: 0px;
    }
`;

export const ContainerPaymentsMethods = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    width:calc(454px - 32px);

    padding: 16px;

    background-color: ${NEUTRAL_COLORS.WHITE};

    border-radius: 16px;
    box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        position: relative;
        border-radius: none;
        box-shadow: none;
        width: 100%;
        padding: 0px;
    }
`;

export const ContentPaymentMethods = styled.div`
    display: flex;
    flex-direction: column;

    strong{
            ${FONT.BODY.BODY_MEDIUM[600]}
            margin-bottom: 6px;
    }
`;

export const TitleCard = styled.h1`
    font-size: ${FONT_SIZE.SMALL};
    font-weight: ${FONT_WEIGTH.HUGE};
    line-height: 140% ;
    color: ${NEUTRAL_COLORS.HIGH_DARK};`;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;

    gap:94px;

    p{
        font-weight: ${FONT_WEIGTH.LARGE};
        font-size: ${FONT_SIZE.TINY};
        line-height: 140%;
        color: ${NEUTRAL_COLORS.HIGH_DARK};
        margin-bottom: -8px;
    }
`;

export const Content = styled.div`
   display: flex;
   flex-direction: column;

   width: 100%;

   row-gap: 24px;
    `;


export const PaymentContainer = styled.div`
   display: flex;
   flex-direction: column;

   width: 100%;

   row-gap: 16px;
`;

export const TextCardDefault = styled.p`
    font-weight: ${FONT_WEIGTH.HUGE};
    font-size: ${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const Total = styled.p`
    font-weight: ${FONT_WEIGTH.HUGE};
    font-size:${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const LittleText = styled.p`
    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.TINY};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const DefaultCard = styled.div`
    display:flex;
    flex-direction: column;

    row-gap: 24px;
`;

export const PaymentDefault = styled.div`
    display:grid;

    width: 100%;
    max-width: 506px;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    grid-template-areas:
    "cardNumber textCard"
    "h value";

    justify-content: space-between;

    ${TextCardDefault}{
        grid-area: cardNumber;

    }
    ${Total}{
        width: 100%;
        grid-area: value;
        text-align: end;
    }
    ${LittleText}{
        width: 100%;
        grid-area: textCard;
        text-align: center;
        margin-left: -6px;
    }
    `;

export const Divisor = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    align-items: center;
    justify-content: space-between;

    &::before{
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${NEUTRAL_COLORS.GRAY};

        margin-right: 16px;

    }

    &::after{
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${NEUTRAL_COLORS.GRAY};

        margin-left: 16px;
    }

    p{
        font-weight: ${FONT_WEIGTH.MEDIUM};
        font-size: ${FONT_SIZE.SMALL};
        line-height: 140%;
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const InputContainer = styled.div`

    display: flex;
    flex-direction: column;

    row-gap: 0px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    width: 100%;

    `;

export const ContainerGuest = styled.div`
display: flex;

width: calc(100% - 32px);
height: 28px;

flex: none;
order: 1;
flex-grow: 0;

padding: 12px 16px;

border-top: 1px solid ${NEUTRAL_COLORS.GRAY};
`;

export const ContentGuest = styled.div`
display: flex;
width: 100%;
flex-direction: row;
align-items: center;
padding: 0px;
justify-content: space-between;
`;

export const GuestText = styled.p`

font-weight: ${FONT_WEIGTH.LARGE};
font-size: ${FONT_SIZE.TINY};
line-height: 140%;

color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const InputGuest = styled.div`
display: flex;

width: 16px;
height: 22px;

font-weight: ${FONT_WEIGTH.LARGE};
font-size: ${FONT_SIZE.SMALL};
line-height: 140%;

align-items: center;
justify-content: center;
text-align: center;
`;

export const RowGuests = styled.div`
display: flex;
flex-direction: row;

align-items: center;

padding: 0px;
gap: 16px;

width: 108px;
height: 32px;
`;

export const RowText = styled.p`
    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

interface DiscountProps {
    discount?:boolean;
    include?:boolean;
}

export const ContainerValues = styled.div<DiscountProps>`
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;

    label{
        color: ${({include}) => include && STATUS_COLORS.SUCCESS }
    }

    ${({discount}) => discount && css`
        position: relative;
        margin-right: -45px;

        justify-self: end;

        color:${NEUTRAL_COLORS.DARK_GRAY};

        gap: 4px;

        ::before{
            position: absolute;

            content: "";

            width: calc(100% - 24px);
            height: 1px;

            background-color: ${NEUTRAL_COLORS.DARK_GRAY};

            top: 45%;
        }
    `};
    `;

export const DiscountValues = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;

    justify-self: end;
`;

export const RowValues = styled.div<DiscountProps>`
    width:fit-content;

    text-align: end;

    border: 0;
    outline: none;

    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${({discount}) => discount ? NEUTRAL_COLORS.DARK_GRAY : NEUTRAL_COLORS.HIGH_DARK} ;

`;

export const Discount = styled.p`
    color: ${STATUS_COLORS.ERROR};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.TINY};
    line-height: 140%;

    grid-column-start:2 span ;

    place-self: end end;
    white-space: nowrap;

    margin-right: -140px;
`;

export const Rows = styled.div<DiscountProps>`
    display: grid;

    grid-template-columns: 1fr 1fr;

    ${({discount}) => discount && "grid-template-columns: 1fr 1fr 1fr;"}
    justify-content: space-between;
    `;

export const CleaningRow = styled.div`
    display: flex;

    gap: 8px;

    align-items: center;
    position: relative;
`;

export const TotalRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    `;

export const PrefixTotal = styled.label`
    font-weight: ${FONT_WEIGTH.HUGE};
    font-size:${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const TotalValue = styled.div`
    max-width:auto;
    text-align: end;

    border: 0;
    outline: none;

    font-weight: ${FONT_WEIGTH.HUGE};
    font-size:${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const ContainerUndestandValues = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 40px;

    align-items: center;
    justify-content: space-between;

    `;

export const CupomContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    width: 219px;
    height: 40px;


    gap: 8px;

    border: 1px solid #B9B9B9;
    border-radius: 10px;

    img{
        width: 24px;
        height: 24px;
    }

    p{
        line-height: 140%;
        font-size: ${FONT_SIZE.TINY};
        font-weight: ${FONT_WEIGTH.MEDIUM};
        color: ${NEUTRAL_COLORS.HIGH_DARK}
    }

    cursor: pointer;

    `;

export const RowUndestandValue = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    gap:10px;

    img{
        width: 24px;
        height: 24px;
    }

    p{

    font-weight: ${FONT_WEIGTH.LARGE};
    font-size: ${FONT_SIZE.TINY};
    line-height: 140%;

    color: ${PRIMARY_COLORS.MEDIUM};

    flex: none;
    order: 2;
    flex-grow: 0;
    }

    cursor: pointer;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    align-items: center;

    margin-top: -8px;

    p{
        font-weight: ${FONT_WEIGTH.MEDIUM};
        font-size: ${FONT_SIZE.TINY};
        line-height: 140%;
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    `;

export const FooterButtons = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 24px;
`;

export const PaymentMethodRow = styled.div`
    display: flex;

    align-items: center;

    gap: 8px;

    p {
        ${FONT.BODY.BODY_MEDIUM[400]}
    }

    padding: 8px 0px;

    img {
        width: 20px;
        height: 20px;
    }
`;

export const CalcelPolitcs = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    border-top: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    margin-top: 8px;

    div:first-child {
        margin-top: 4px;
    }
`;

export const MethodPaymentLink = styled(Link)`
    ${FONT.BODY.BODY_SMALL[400]}
    color: ${PRIMARY_COLORS.MEDIUM};
`