import { useNavigate } from 'react-router-dom';
import * as S from './styles'
import { Button } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { useEffect, useState } from 'react';
import { useAnyProvider } from 'hooks/Context';
import { useSpaProvider } from 'hooks/spaRouterContext';
import MobileCarouselProperties from 'components/MobileCarouselProperties';
import { NavBar } from 'components/NavBar';
import { AutoCompleteInput } from 'components/AutoCompletInput';
import { useHeader } from 'components/Header/useHeader';
import { useAutoCompletInput } from 'components/AutoCompletInput/useAutoCompletInput';
import { WhatsappButton } from 'components/WhatsappButton';
import { CalendarComponent } from 'components/Calendar';
import dayjs from 'dayjs';
import { GuestSelector } from 'components/Header/components/GuestSelector';
const iconError = require('assets/svg/error/WarningError.svg').default

export const SearchMobile: React.FC = () => {

    const arrowBack = require('assets/svg/LeftArrow.svg').default;

    const [date, setDate] = useState<Date[] | null>([]);

    const searchIcon = require('../../../assets/svg/MagnifyingGlass.svg').default;
    const [inputSearchValue, setInputSearchValue] = useState('')
    const [inputTypeSearchValue, setInputTypeSearchValue] = useState('')

    const { setSearchType, setSearchValue, setAutoCompletSearch } = useAnyProvider();

    const checkInput = (e: any) => {
        setInputSearchValue(e);
        if (e !== '') {
            setErrorSearch(false);
        } else {
            setErrorSearch(true);
        }
    };

    const { pressEnterSelectOption } = useAutoCompletInput({ setInputSearchValue: (e: any) => checkInput(e), inputSearchValue, isOpen: true, setInputTypeSearchValue, date })

    const navigate = useNavigate()

    const { carouselTitle, array, buildings, errorSearch, promotionalUnits, units, address, setErrorSearch } = useHeader({ setInputSearchValue });

    const { expanded, setPageChosen, pageChosen } = useSpaProvider();


    function handleCarousel(): any {
        switch (pageChosen) {
            case 0: {
                return <MobileCarouselProperties type='default' hasFrom card='unit' key={`carousel-${pageChosen}`} titleCarousel={carouselTitle} data={units} />;
            }
            case 1: {
                return <MobileCarouselProperties type='default' hasFrom card='unit' key={`carousel-${pageChosen}`} titleCarousel={carouselTitle} data={promotionalUnits} />;
            }
            case 2: {
                return <MobileCarouselProperties hasFrom type='unit' card='building' key={`carousel-${pageChosen}`} titleCarousel={carouselTitle} data={buildings} />;
            }
            // case 3: {
            //     return <MobileCarouselProperties hasFrom type='unit' card='building' key={`carousel-${pageChosen}`} titleCarousel={carouselTitle} data={buildings} />;
            // }
        }
    }


    const carousel = handleCarousel()


    const [isOpenAutoComplete, setIsOpenAutoComplete] = useState(false)

    const handleButtonClick = () => {
        if (inputSearchValue === '') {
            setSearchValue('Curitiba')
            setSearchType('city')
            if (date && date?.length > 0 && date[0] && date[1]) {
                navigate('/properties/?search=Curitiba&type=city&check_in=' + dayjs(date[0]).format('YYYY-MM-DD') + '&check_out=' + dayjs(date[1]).format('YYYY-MM-DD'))
                setAutoCompletSearch(dayjs(date[0]).format('YYYY-MM-DD') + dayjs(date[1]).format('YYYY-MM-DD'))
            } else {
                navigate('/properties/?search=Curitiba&type=city_building')
            }
        } else {
            setErrorSearch(false)
            pressEnterSelectOption(new MouseEvent("click"))
            setSearchType(inputTypeSearchValue)
            setSearchValue(inputSearchValue)
        }
    };

    return (
        <S.Container>
            <S.Header>
                <S.Back>
                    <img src={arrowBack} onClick={() => navigate(-1)} alt='Voltar página' />
                </S.Back>
                <h1>Escolha um lugar</h1>
            </S.Header>
            <S.ContainerModal>
                <div className='teste' style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>

                    <AutoCompleteInput isOpen={isOpenAutoComplete} setIsOpen={setIsOpenAutoComplete} expanded={expanded} widthDrop={'100%'} hasResult={'has'} onChange={() => { }} placeholder={'Busque por cidade, unidade, bairro...'} inputSearchValue={inputSearchValue} setInputSearchValue={(e: any) => checkInput(e)} options={address} setInputTypeSearchValue={setInputTypeSearchValue} />
                    {
                        inputSearchValue === '' && errorSearch === true && (
                            <div style={{ display: 'flex', width: '110%', flexDirection: 'row', gap: 4, marginTop: 4 }}>
                                <img src={iconError} alt="" />
                                <S.ErrorInput>Busque por um local</S.ErrorInput>

                            </div>

                        )
                    }

                </div>
                <CalendarComponent date={date} setDate={setDate} />
                <GuestSelector fill />
                <S.NavContainer>
                    <NavBar selected={pageChosen} setSelected={setPageChosen} />
                </S.NavContainer>
                <S.CarrouselContainer>
                    {carousel}
                </S.CarrouselContainer>
                <S.ButtonContainer>
                    <Button title='Buscar' icon={searchIcon} iconDirection='left' iconHeight={24} iconWidth={24} size='large' widthSize={'calc(100% + 30px)'} type='primary' disabled={false} onClickButton={() => handleButtonClick()} />
                </S.ButtonContainer>
            </S.ContainerModal>
            <WhatsappButton />
        </S.Container>
    )
}