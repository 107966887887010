type IconProps = {
    size?: number;
    color?: string;
};

export const Plus = ({ color = '#161616', size = 16 }: IconProps) => {
    return (
        <svg width={size} height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 8H13.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M8 2.5V13.5" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}


