import { useTranslation } from "react-i18next";
import { z } from "zod";

export const useValidationCheckinSchema = () => {
    const { t } = useTranslation();

    const schema = z.object({
      codeReservation: z.string({
          required_error: t('checkin.initialPage.errorRequeridCode')
        })
        .trim()
        .nonempty({ message: t('checkin.initialPage.errorRequeridCode') }),
    });

    return schema;
};
