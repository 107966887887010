import { useNavigate } from 'react-router-dom';
import * as S from './styles'
import { useUserProvider } from 'hooks/userContext';
import { Button } from 'components/Button';
import { Icons } from 'components/Icons';
import { Modal } from 'components/Modal';

export const Menu: React.FC = () => {

    const navigate = useNavigate();

    const arrowBack = require('assets/svg/LeftArrow.svg').default;
    const menuIcon = require('assets/svg/List.svg').default;
    const avatarDefault = require('assets/svg/AvatarDefault.svg').default;

    //----------------------------------// icons modal
    const userRegisterIcon = require("assets/svg/user/UserCirclePlus.svg").default
    const logoutIcon = require("assets/svg/user/Logout.svg").default
    const accountIcon = require("assets/svg/user/User.svg").default
    const reservesIcon = require("assets/svg/user/CalendarCheck.svg").default
    const favoritesIcon = require("assets/svg/user/Heart.svg").default
    //----------------------------------//

    const carretRight = require('assets/svg/CaretRightLightGray.svg').default;

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('customer');
        localStorage.removeItem('userName');
        localStorage.removeItem('userAvatar');
        localStorage.removeItem('userEmail');
        localStorage.removeItem('userPhone');
        localStorage.removeItem('userDocument');
        window.location.reload();
    }

    const { userName, userAvatar } = useUserProvider();

    const validPhoto = (photo: string) => {
        if (photo === undefined || photo === null) {
            return avatarDefault
        } else if (photo.length > 0) {
            return photo
        } else {
            return avatarDefault
        }
    }


    return (
        <S.Container>
            <S.Header>
                <S.Back>
                    <img src={arrowBack} onClick={() => navigate(-1)} alt='Voltar página' />
                </S.Back>
                <h1>Menu</h1>
            </S.Header>
            <S.ContainerModal>
                {userName !== '' ? (
                    <S.User onClick={() => navigate('/minha-conta')}>
                        <img src={validPhoto(userAvatar)} alt="" />
                        <S.Username>
                            <h1>Olá,</h1>
                            <h1>{userName}</h1>
                        </S.Username>
                    </S.User>
                ) : (
                    <div>
                        <S.SectionModal>
                            <S.Row>
                                <S.TynyText className='null'>Minha conta:</S.TynyText>
                                <S.SubTitle>Entre para ver suas reservas, favoritos
                                    e gerencias sua conta</S.SubTitle>
                            </S.Row>
                            <Button onClickButton={() => navigate('/login')} size='medium' title='Entrar' type='primary' widthSize={'100%'} />
                            <S.ModalNavLink onClick={() => navigate('/signup')}>
                                <div>
                                    <Icons icon={userRegisterIcon} width={20} height={19} />
                                    <p>Cadastrar-se</p>
                                </div>
                                <img src={carretRight} alt='' />
                            </S.ModalNavLink>
                        </S.SectionModal>
                    </div>
                )}
                {userName !== '' && (
                    <S.SectionModal>
                        <S.LinksCollumn>
                            <S.TynyText className='null'>Minha conta:</S.TynyText>
                            <S.ModalNavLink onClick={() => navigate('/minha-conta')}>
                                <div>
                                    <img src={accountIcon} alt='' />
                                    <p>Conta</p>
                                </div>
                                <img src={carretRight} alt='' />
                            </S.ModalNavLink>
                            <S.ModalNavLink onClick={() => navigate('/minha-conta/reservas/')}>
                                <div>
                                    <img src={reservesIcon} alt='' />
                                    <p>Minhas reservas</p>
                                </div>
                                <img src={carretRight} alt='' />
                            </S.ModalNavLink>
                            <S.ModalNavLink onClick={handleLogout}>
                                <div>
                                    <img src={logoutIcon} alt='' />
                                    <p>Sair</p>
                                </div>
                                <img src={carretRight} alt='' />
                            </S.ModalNavLink>
                        </S.LinksCollumn>
                    </S.SectionModal>
                )}
                <S.SectionModalLinks>
                    <S.TynyText className='space'>Espaço Yogha:</S.TynyText>
                    <S.ModalNavLink>
                        <S.CustomLink to={'https://blog.yogha.com.br/'}>Blog da Yogha</S.CustomLink> <img src={carretRight} alt='' />
                    </S.ModalNavLink>
                    <S.ModalNavLink>
                        <S.CustomLink to={'/moradia-flexivel/'}>Para moradores</S.CustomLink> <img src={carretRight} alt='' />
                    </S.ModalNavLink>
                    <S.ModalNavLink>
                        <S.CustomLink to={'https://negocios.yogha.com.br/'}>Para proprietários</S.CustomLink> <img src={carretRight} alt='' />
                    </S.ModalNavLink>
                    <S.ModalNavLink>
                        <S.CustomLink to={'https://negocios.yogha.com.br/parceiros/'}>Para parceiros</S.CustomLink> <img src={carretRight} alt='' />
                    </S.ModalNavLink>
                </S.SectionModalLinks>

            </S.ContainerModal>

        </S.Container>
    )
}