import styled, { css } from "styled-components";
import { NEUTRAL_COLORS, PRIMARY_COLORS, STATUS_COLORS } from "../../../../constants/styleConstants";
import { FONT, FONT_SIZE, FONT_WEIGTH } from "../../../../constants/fontConstants";

export const Container = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-start;

    width: calc(469px - 48px);

    justify-self: end;

    padding: 24px 24px 20px;

    background-color: ${NEUTRAL_COLORS.WHITE};

    border-radius: 16px;
    box-shadow: 0px 8px 35px rgba(0, 0, 0, 0.1);

    row-gap: 32px;

    @media (max-width: 768px) {
        width: 100%;
        border-radius: none;
        box-shadow: none;
        padding: 0px;
    }

`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    gap:40px;

    align-items: start;

    padding-bottom: 32px;

    border-bottom: 1px solid ${NEUTRAL_COLORS.GRAY};

    @media (max-width: 768px) {

        gap: 10px;
    }
`;

export const ImageAccommodation = styled.div`
    display: flex;

    max-height: 118px;
    max-width: 133px;

    border-radius: 8px;

    img{
        min-height: 118px;
        min-width: 133px;

        object-fit: cover;

        border-radius: 8px;
    }

    @media (max-width: 768px) {
        max-height: 149px;
        max-width: 132px;
        img{
        min-height: 149px;
        min-width: 133px;

        object-fit: cover;

        border-radius: 8px;
    }
    }
`;

export const Subtitle = styled.h2`
    ${FONT.BODY.BODY_MEDIUM[600]};
    width: 100%;

    color: ${NEUTRAL_COLORS.HIGH_DARK};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
        white-space: unset;
    }
`;

export const AccommodationInfo = styled.div`
    display: flex;
    flex-direction: column;

    width: auto;

    row-gap: 8px;

    overflow: hidden;
    p{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px !important;
    }

    @media (max-width: 768px) {
        width: 100%;
        overflow: hidden;
    p{
        white-space: unset;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px !important;
    }
    }
`;
export const TitlePayment = styled.h1`
    width: 100%;
    text-align: center;
`;
export const TitleCard = styled.h1`
    ${FONT.BODY.BODY_MEDIUM[600]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    font-size: 16px !important;

    @media (max-width: 768px) {
         margin-bottom: -16px;
    }
    `;

export const LabelContainer = styled.div`
    display: flex;
    flex-direction: row;

    margin-top: 16px;

    gap:50px;

    span{
        ${FONT.BODY.BODY_SMALL[600]};
         color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    p{
        ${FONT.BODY.BODY_SMALL[400]}
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    > div{
        position: relative;
        :nth-child(2){
            ::before{
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};
                left: -25px;
                top: 0;
            }
        }
    }

    @media (max-width: 768px) {
        gap: 24px;


        > div{
        position: relative;
        :nth-child(2){
            ::before{
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};
                left: -12px;
                top: 0;
            }
        }
    }
    }
`;

export const Content = styled.div`
   display: flex;
   flex-direction: column;

   width: 100%;

   row-gap: 24px;
    `;


export const PaymentContainer = styled.div`
   display: flex;
   flex-direction: column;

   width: 100%;

   row-gap: 16px;
`;

export const TextCardDefault = styled.p`
    font-weight: ${FONT_WEIGTH.HUGE};
    font-size: ${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const Total = styled.h3`
    ${FONT.BODY.BODY_LARGE[600]}
    font-size: 16px !important;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const LittleText = styled.p`
    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.TINY};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const DefaultCard = styled.div`
    display:flex;
    flex-direction: column;

    row-gap: 24px;
`;

export const PaymentDefault = styled.div`
    display:grid;

    width: 100%;
    max-width: 506px;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    grid-template-areas:
    "cardNumber textCard"
    "h value";

    justify-content: space-between;

    ${TextCardDefault}{
        grid-area: cardNumber;

    }
    ${Total}{
        width: 100%;
        grid-area: value;
        text-align: end;
    }
    ${LittleText}{
        width: 100%;
        grid-area: textCard;
        text-align: center;
        margin-left: -6px;
    }
    `;

export const Divisor = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;

    align-items: center;
    justify-content: space-between;

    &::before{
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${NEUTRAL_COLORS.GRAY};

        margin-right: 16px;

    }

    &::after{
        content: "";
        width: 100%;
        height: 1px;
        background-color: ${NEUTRAL_COLORS.GRAY};

        margin-left: 16px;
    }

    p{
        font-weight: ${FONT_WEIGTH.MEDIUM};
        font-size: ${FONT_SIZE.SMALL};
        line-height: 140%;
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }
`;

export const InputContainer = styled.div`

    display: flex;
    flex-direction: column;

    row-gap: 0px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    width: 100%;

    `;

export const ContainerGuest = styled.div`
display: flex;

width: calc(100% - 32px);
height: 28px;

flex: none;
order: 1;
flex-grow: 0;

padding: 12px 16px;

border-top: 1px solid ${NEUTRAL_COLORS.GRAY};
`;

export const ContentGuest = styled.div`
display: flex;
width: 100%;
flex-direction: row;
align-items: center;
padding: 0px;
justify-content: space-between;
`;

export const GuestText = styled.p`

font-weight: ${FONT_WEIGTH.LARGE};
font-size: ${FONT_SIZE.TINY};
line-height: 140%;

color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

export const InputGuest = styled.div`
display: flex;

width: 16px;
height: 22px;

font-weight: ${FONT_WEIGTH.LARGE};
font-size: ${FONT_SIZE.SMALL};
line-height: 140%;

align-items: center;
justify-content: center;
text-align: center;
`;

export const RowGuests = styled.div`
display: flex;
flex-direction: row;

align-items: center;

padding: 0px;
gap: 16px;

width: 108px;
height: 32px;
`;

export const RowText = styled.p`
    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${NEUTRAL_COLORS.HIGH_DARK};
`;

interface DiscountProps {
    discount?:boolean;
    include?:boolean;
    hasDiscount?:boolean;
}

export const ContainerValues = styled.div<DiscountProps>`
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;

    label{
        color: ${({include}) => include && STATUS_COLORS.SUCCESS }
    }

    ${({discount}) => discount && css`
        position: relative;
        margin-right: -45px;

        justify-self: end;

        color:${NEUTRAL_COLORS.DARK_GRAY};

        gap: 4px;

        ::before{
            position: absolute;

            content: "";

            width: calc(100% - 24px);
            height: 1px;

            background-color: ${NEUTRAL_COLORS.DARK_GRAY};

            top: 45%;
        }
    `};
    `;

export const ContainerPixDiscount = styled(ContainerValues)`
    gap: 8px;
`;

export const DiscountValues = styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;

    justify-self: end;
`;

export const RowValues = styled.div<DiscountProps>`
    width:fit-content;

    text-align: end;

    border: 0;
    outline: none;

    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.SMALL};
    line-height: 140%;
    color: ${({discount}) => discount ? NEUTRAL_COLORS.DARK_GRAY : NEUTRAL_COLORS.HIGH_DARK} ;

    ${({hasDiscount}) => hasDiscount && css`
        text-decoration: line-through;
        color: ${NEUTRAL_COLORS.GRAY};
    `}

`;

export const Discount = styled.p`
    color: ${STATUS_COLORS.ERROR};
    font-weight: ${FONT_WEIGTH.MEDIUM};
    font-size: ${FONT_SIZE.TINY};
    line-height: 140%;

    grid-column-start:2 span ;

    place-self: end end;
    white-space: nowrap;

    margin-right: -140px;
`;

export const Rows = styled.div<DiscountProps>`
    display: grid;

    grid-template-columns: 1fr 1fr;

    ${({discount}) => discount && "grid-template-columns: 1fr 1fr 1fr;"}
    justify-content: space-between;

    @media (max-width: 768px) {
        display: none;
    }
    `;

export const TotalRow = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    `;

export const RowTotalValue = styled.div`
    display: flex;
    gap: 4px;
`;

export const PrefixTotal = styled(Total)<{hasDiscount?:boolean}>`
${({hasDiscount}) => hasDiscount && css`
        text-decoration: line-through;
        color: ${NEUTRAL_COLORS.GRAY};
        ${FONT.BODY.BODY_SMALL[400]};
    `}
`;
export const Prefix = styled.label<{hasDiscount?:boolean}>`
${({hasDiscount}) => hasDiscount && css`
        text-decoration: line-through;
        color: ${NEUTRAL_COLORS.GRAY};
        ${FONT.BODY.BODY_SMALL[400]};
    `}
`;

export const TotalValue = styled.h3<{hasDiscount?:boolean}>`
    max-width:auto;
    text-align: end;
    ${FONT.BODY.BODY_LARGE[600]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    font-size: 16px !important;

    ${({hasDiscount}) => hasDiscount && css`
        text-decoration: line-through;
        color: ${NEUTRAL_COLORS.GRAY};
        ${FONT.BODY.BODY_SMALL[400]};
    `}
`;

export const ContainerUndestandValues = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    height: 32px;

    align-items: center;
    justify-content: space-between;

    `;

export const CupomContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: center;

    width: 219px;
    height: 40px;


    gap: 8px;

    border: 1px solid #B9B9B9;
    border-radius: 10px;

    img{
        width: 24px;
        height: 24px;
    }

    p{
        line-height: 140%;
        font-size: ${FONT_SIZE.TINY};
        font-weight: ${FONT_WEIGTH.MEDIUM};
        color: ${NEUTRAL_COLORS.HIGH_DARK}
    }

    cursor: pointer;

    `;

export const RowUndestandValue = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    gap:10px;

    img{
        width: 24px;
        height: 24px;
    }

    p{

    font-weight: ${FONT_WEIGTH.LARGE};
    font-size: ${FONT_SIZE.TINY};
    line-height: 140%;

    color: ${PRIMARY_COLORS.MEDIUM};

    flex: none;
    order: 2;
    flex-grow: 0;
    }

    cursor: pointer;
`;

export const Footer = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;

    align-items: center;

    margin-top: -8px;

    p{
        font-weight: ${FONT_WEIGTH.MEDIUM};
        font-size: ${FONT_SIZE.TINY};
        line-height: 140%;
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    `;

export const ContentFooter = styled.div`
    display: flex;

    width: 100%;

    justify-content: flex-end;
    align-items: center;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
    }
`;

export const FooterButtons = styled.div`
    display: flex;
    flex-direction: column;

    row-gap: 24px;
`;

export const ActionsFooter = styled.div`
    display: flex;

    width: 100%;

    justify-content: space-between;

`;

export const CupomButton = styled.div`
    display: flex;

    align-items: center;
    justify-content: center;

    gap: 8px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};

    border-radius: 10px;

    padding: 8px 16px;

    p{
        ${FONT.BODY.BODY_SMALL[400]};
    }

    :has(img):not(:has(button)){
        img{
            width: 24px;
            height: 24px;
        }
        cursor: pointer;
    }

    :has(button){
        button{
            cursor: pointer;

            border: none;
            background-color: transparent;

            img{
                width: 10px;
                height: 10px;
            }
        }
        p{
            color: ${STATUS_COLORS.SUCCESS};
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 24px;
    }
`;

export const ContainerButtonCupom = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
`;

export const ExplanationText = styled.p`
    ${FONT.BODY.BODY_SMALL[400]};
    color: ${NEUTRAL_COLORS.HIGH_DARK};
    margin-bottom: -8px;
`;

export const ContainerCupomExplanation = styled.div`
    display: flex;

    align-items: flex-start;
    gap: 12px;
`;