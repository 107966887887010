import { Footer } from "components/Footer";
import * as S from "./styles";
import { Button } from "components/Button";
import { HeaderAlt } from "components/HeaderAlt";
import { useNavigate } from "react-router-dom";
import { useUserProvider } from "hooks/userContext";
import useListenerCupom from "pages/Payment/components/PaymentCard/useListenerCupom";


export const CompletePaymente = (): JSX.Element => {

  document.title = "Pagamento Confirmado | Yogha";

  const navigate = useNavigate();

  useListenerCupom()

  const calendar = <svg
    className="calendar-check"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26 4H23V3C23 2.73478 22.8946 2.48043 22.7071 2.29289C22.5196 2.10536 22.2652 2 22 2C21.7348 2 21.4804 2.10536 21.2929 2.29289C21.1054 2.48043 21 2.73478 21 3V4H11V3C11 2.73478 10.8946 2.48043 10.7071 2.29289C10.5196 2.10536 10.2652 2 10 2C9.73478 2 9.48043 2.10536 9.29289 2.29289C9.10536 2.48043 9 2.73478 9 3V4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V26C4 26.5304 4.21071 27.0391 4.58579 27.4142C4.96086 27.7893 5.46957 28 6 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V6C28 5.46957 27.7893 4.96086 27.4142 4.58579C27.0391 4.21071 26.5304 4 26 4ZM9 6V7C9 7.26522 9.10536 7.51957 9.29289 7.70711C9.48043 7.89464 9.73478 8 10 8C10.2652 8 10.5196 7.89464 10.7071 7.70711C10.8946 7.51957 11 7.26522 11 7V6H21V7C21 7.26522 21.1054 7.51957 21.2929 7.70711C21.4804 7.89464 21.7348 8 22 8C22.2652 8 22.5196 7.89464 22.7071 7.70711C22.8946 7.51957 23 7.26522 23 7V6H26V10H6V6H9ZM26 26H6V12H26V26ZM21.2075 15.2925C21.3005 15.3854 21.3742 15.4957 21.4246 15.6171C21.4749 15.7385 21.5008 15.8686 21.5008 16C21.5008 16.1314 21.4749 16.2615 21.4246 16.3829C21.3742 16.5043 21.3005 16.6146 21.2075 16.7075L15.2075 22.7075C15.1146 22.8005 15.0043 22.8742 14.8829 22.9246C14.7615 22.9749 14.6314 23.0008 14.5 23.0008C14.3686 23.0008 14.2385 22.9749 14.1171 22.9246C13.9957 22.8742 13.8854 22.8005 13.7925 22.7075L10.7925 19.7075C10.6049 19.5199 10.4994 19.2654 10.4994 19C10.4994 18.7346 10.6049 18.4801 10.7925 18.2925C10.9801 18.1049 11.2346 17.9994 11.5 17.9994C11.7654 17.9994 12.0199 18.1049 12.2075 18.2925L14.5 20.5863L19.7925 15.2925C19.8854 15.1995 19.9957 15.1258 20.1171 15.0754C20.2385 15.0251 20.3686 14.9992 20.5 14.9992C20.6314 14.9992 20.7615 15.0251 20.8829 15.0754C21.0043 15.1258 21.1146 15.1995 21.2075 15.2925Z"
      fill="white"
    />
  </svg>

  const { userName } = useUserProvider();

  return (
    <S.ContainerPage>
      <HeaderAlt />
      <S.Container>
        <S.Content>
          <S.Header className="rectangle-75">
            {calendar}
            <S.HeaderText>Reserva confirmada</S.HeaderText>
          </S.Header>
          <S.ContainerInfo>
            <h1>
              Para realizar a sua entrada na unidade, você precisa fazer o <S.Highlight>Check-in Online</S.Highlight> através do link que você receberá por e-mail</h1>
          </S.ContainerInfo>
          <S.UserMessage>
            <span>{userName}</span>
            <p>É muito bom ter você na Yogha!</p>
          </S.UserMessage>
          <S.Concluded>
            <p>Sua reserva foi confirmada. Você receberá um email com mais informações mas também pode ver mais detalhes em <S.Highlight>Minhas reservas</S.Highlight></p>
          </S.Concluded>

          <S.ButtonContainer>
            <Button onClickButton={() => navigate('/')} size="medium" title="Continuar navegando" type="primary" />
            <Button onClickButton={() => navigate('/minha-conta/reservas/')} size="medium" title="Minhas reservas" type="secondary" />
          </S.ButtonContainer>


        </S.Content>
      </S.Container>
      <Footer />
    </S.ContainerPage>
  );
};
