type IconProps = {
    size?: number;
    color?: string;
};

export const Minus = ({ color = '#161616', size = 16 }: IconProps) => {
    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 8H13.5" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}




