// store.ts
import {create} from 'zustand';

type StepStore = {
    step: number;
    setStep: (step: number) => void;
};

export const useStepStore = create<StepStore>((set) => ({
    step: 0, // valor inicial do step
    setStep: (step) => set({ step }), // função para atualizar o step
}));
