import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled, { css } from "styled-components";

export const Container = styled.div`
`;
export const DaysWeekContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    row-gap: 4px;
    column-gap: 8px;

    place-items: center;
    place-content: center;

    min-width: 300px;

    position: relative;

    p{
        ${FONT.BODY.BODY_SMALL[500]};
        color: ${NEUTRAL_COLORS.DARK_GRAY};
        margin-bottom: 6px;
    }

    ::after{
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 22px;
        width: 97%;
        height: 2px;
        background-color: ${NEUTRAL_COLORS.LIGHT_GRAY};
    }

@media (max-width: 360px) {
    row-gap: 4px;
    column-gap: 0px;
}
@media (min-width: 360px) AND (max-width: 380px) {
    row-gap: 4px;
    column-gap: 4px;
}

`;

interface DayButtonProps {
    isSelectedStart?: boolean;
    isSelectedEnd?: boolean;
    isBetween?: boolean;
    isBefore?: boolean;
    isPrevOrNext?: boolean;
    daysStartedOnWeekend?: boolean;
    isBlocked?: boolean;
    isCheckin?: boolean;
    isCheckout?: boolean;
}

export const DayButton = styled.button<DayButtonProps>`
    display: flex;
    position: relative;
    background-color: ${({ isSelectedStart,isSelectedEnd,isBetween,isPrevOrNext,daysStartedOnWeekend, isCheckin}) => isCheckin && isSelectedStart ? NEUTRAL_COLORS.LIGHT_GRAY : isSelectedStart || isSelectedEnd ? PRIMARY_COLORS.MEDIUM : isBetween ? PRIMARY_COLORS.LIGHTEST : isPrevOrNext  ? NEUTRAL_COLORS.LIGHT_GRAY : daysStartedOnWeekend ? '#fff' :  '#fff' } ;
    :hover:not(:disabled){
        background-color: ${({ isSelectedStart,isSelectedEnd,isBetween,isPrevOrNext,daysStartedOnWeekend, isCheckin}) => isSelectedStart &&  isCheckin? NEUTRAL_COLORS.LIGHT_GRAY : isSelectedStart || isSelectedEnd ? PRIMARY_COLORS.MEDIUM : isBetween ? PRIMARY_COLORS.LIGHTEST : isPrevOrNext  ? NEUTRAL_COLORS.LIGHT_GRAY : daysStartedOnWeekend ? '#F6F4F4' :  '#F6F4F4' } ;
    }

    :not(:disabled){
        cursor: pointer;
    }

    justify-content: center;

    /* :hover:not(:disabled){
        background-color: ${NEUTRAL_COLORS.GRAY} ;
    } */

    border: none;

    ${FONT.BODY.BODY_SMALL[500]};
    line-height: 24px;

    height: 44px;
    width: 44px;

    align-items: center;
    justify-content: center;

    cursor: 'pointer';

    color: ${({isSelectedStart,isSelectedEnd,isBetween,isPrevOrNext,isBefore, isCheckin,}) => isCheckin && isSelectedStart ? NEUTRAL_COLORS.HIGH_DARK :isBefore ? NEUTRAL_COLORS.GRAY : isSelectedStart || isSelectedEnd  ?  NEUTRAL_COLORS.WHITE : isCheckin && !isSelectedEnd && !isSelectedStart ? '#6a6a6a': NEUTRAL_COLORS.DARK};

    ${({isBetween, daysStartedOnWeekend,isSelectedEnd, }) => isBetween || isSelectedEnd  ? css`
        ::after{
            content: '';
            display: block;
            position: absolute;
            left: ${daysStartedOnWeekend ? '0' : '-20px'};
            top: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background-color:  ${PRIMARY_COLORS.LIGHTEST};
            z-index: -1;
        }
    `: ''}
        ${({isSelectedStart, isSelectedEnd , isCheckin}) =>  isCheckin && isSelectedStart ? css`
        border-radius: 24px;
        `:  isSelectedStart  ? css`
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    `:  isSelectedEnd ? css`
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    `: ''}

    ${({isBlocked, isPrevOrNext,isBefore}) => isBlocked || isPrevOrNext || isBefore  ? css`
        ::before{
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: 50%;
            height: 1px;
            background-color:  ${NEUTRAL_COLORS.GRAY};
        }
    `: ''}

                `;

export const LeftCalendarContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
    justify-content: center;
`;

export const Calendars = styled.div`
    display: flex;
    gap: 24px;

    align-items: flex-start;
    padding-top: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
    z-index: 10;

`;

export const ContainerCalendars = styled.div`
    display: flex;
    flex-direction: column;

    position: absolute;
    top: 65px;
    left: 8%;
    transform: translateX(-50%);

    gap: 10px;

    background-color: ${NEUTRAL_COLORS.WHITE};

    padding: 16px 24px;

    border-radius: 16px;
    box-shadow: 0px 8px 35px 0px rgba(0, 0, 0, 0.10);
    z-index: 1000;
`;

export const RightCalendarContainer = styled(LeftCalendarContainer)``;

export const CalendarHeader = styled.div`
    display: flex;
    position: relative;

    gap: 10px;

    margin-bottom: 10px;

    align-items: center;
    justify-content: center;

    width: 100%;

    div{
        ${FONT.BODY.BODY_MEDIUM[600]};
    }

    button{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border: none;
        cursor: pointer;
        :nth-child(1){
           left: 0;
        }
        :nth-child(2){
            right: 0;
        }
    }
    img{
        width: 24px;
        height: 24px;
    }
`;

type ContainerCalendarProps = {
    openCalendar: boolean;
}

export const ContainerDatePicker = styled.div<ContainerCalendarProps>`
    display: flex;
    flex-direction: column;

    position: relative;

    align-items: center;
    justify-content: center;

    width: 100%;

`;

type InputContainerProps = {
    width?: string;
    noHasBorder?: boolean;
    isCalendarOpen: boolean;
}

export const InputContainer = styled.div<InputContainerProps>`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;

    width: ${({width}) => width ? `calc(${width}  - 32px)` : '302px'};

    padding: 0px 16px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 8px;
    :hover{
        border: 1px solid ${NEUTRAL_COLORS.DARK_GRAY};
    }

    ${({noHasBorder,isCalendarOpen}) => noHasBorder ? css`
        border: none;
        :hover{
            border: none;
        }
        & > div{
            width: fit-content;
            padding-left:0;
        }
        & > div:first-child{
            margin-left: 16px;
            ::after{
                right: -40px;

            }
        }
        & > div:last-child{
            margin-left: 20px;
        }
        @media (max-width: 768px) {
            & > div{
            width: 100%;
            padding-left:0;
        }
        & > div:first-child{
            margin-left: 0px;
            ::after{
                right: -10px;

            }
        }
        & > div:last-child{
            margin-left: 0px;
        }
        }
    `:  isCalendarOpen ? css`
        border: 1px solid ${PRIMARY_COLORS.MEDIUM};
        :hover{
            border: 1px solid ${PRIMARY_COLORS.MEDIUM};
        }
    ` : ''}

    cursor: pointer;

    @media  (max-width: 768px) {
        width: ${({width}) => width ? `${width}px` : '100%'};
    }
`;

type CalendarInputProps = {
    hasDate: boolean;
}

export const CalendarInput = styled.div<CalendarInputProps>`
    display: flex;

    width: 100%;
    border-radius: 6px;
    align-items: center;

    gap: 10px;
    height: 50px;
    :last-child{
        justify-content: center;
        padding-left: 6px;
    }

    input{
        width: 100px;
        border: none;
        outline: ${PRIMARY_COLORS.MEDIUM};
        ${FONT.BODY.BODY_SMALL[400]};
        cursor: pointer;

    }
    border: 1px solid transparent;

    position: relative;

    :nth-child(1){
        ::after{
            content: '';
            position: absolute;
            right: -6px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 100%;
            background-color: ${NEUTRAL_COLORS.GRAY};
        }
    }


`;

export const FooterCalendar = styled.div`
    display: flex;

    width: 100%;

    align-items: center;
    justify-content: flex-end;

    gap: 10px;
`;
export const MobileViewCalendar = styled.div`
    position: fixed;
    display: flex;
    flex-direction: column;

    align-items: center;

    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    overflow: auto;

    background-color: ${NEUTRAL_COLORS.WHITE};
    z-index: 10000;
`;

export const CalendarContainerMobile = styled(LeftCalendarContainer)``;

export const MobileCalerdars= styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin-top: 72px;
    margin-bottom: 100px;
`;

export const MobileCalendarHeader = styled.div`
    display: flex;
    position: fixed;

    width: 100%;

    align-items: center;
    justify-content: center;

    top: 0;
    right: 0;

    height: 60px;


    background-color: ${NEUTRAL_COLORS.WHITE};
    z-index: 10;

    p{
        color: ${NEUTRAL_COLORS.DARK};
        ${FONT.BODY.BODY_MEDIUM[500]};
        text-align: center;
    }

    strong{
        font-weight: 600;

    }
    border-bottom: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
`;

export const CloseButton = styled.button`
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    border: none;
    cursor: pointer;
    background-color: ${NEUTRAL_COLORS.WHITE};
    padding-right: 16px;
    img{
        width: 24px;
        height: 24px;
    }
`;

export const MobileFooterCalendar = styled.div`
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: flex-end;

    gap: 10px;

    position: fixed;
    bottom: 0;
    right: 0;

    background-color: ${NEUTRAL_COLORS.WHITE};

    padding: 12px 24px;

    border-top: 1px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
`;