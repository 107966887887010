import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const useDiscountCookie = () => {
    const [discount, setDiscount] = useState<string | undefined>(Cookies.get('discount'));
    const [cupomId, setCupomId] = useState<string | undefined>(Cookies.get('cupomId'));
    const [isDiscountValid, setIsDiscountValid] = useState<boolean>(Cookies.get('isDiscountValid') === 'true');

    useEffect(() => {
        const handleCookieChange = () => {
            setDiscount(Cookies.get('discount'));
            setIsDiscountValid(Cookies.get('isDiscountValid') === 'true');
            setCupomId(Cookies.get('cupomId'));
        };

        // Ouça eventos de mudança no cookie
        window.addEventListener('cookieChange', handleCookieChange);

        return () => {
            window.removeEventListener('cookieChange', handleCookieChange);
        };
    }, []);

    const saveDiscount = (discountValue: string, isValid: boolean, cupomId:string) => {
        Cookies.set('discount', discountValue, { secure: true, sameSite: 'Strict' });
        Cookies.set('isDiscountValid', isValid.toString(), { secure: true, sameSite: 'Strict' });
        Cookies.set('cupomId', cupomId, { secure: true, sameSite: 'Strict' });
        // Trigger manual change detection
        window.dispatchEvent(new Event('cookieChange'));
    };

    const clearDiscount = () => {
        Cookies.remove('discount');
        Cookies.remove('cupomId');
        Cookies.remove('isDiscountValid');
        setDiscount(undefined);
        setIsDiscountValid(false);
        // Trigger manual change detection
        window.dispatchEvent(new Event('cookieChange'));
    };



    return { discount, isDiscountValid, saveDiscount, clearDiscount, cupomId,  };
};
