import React, { useState, useRef } from 'react';
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { ButtonCarousel } from 'components/ButtonCarousel';
import { Card } from 'components/Card';
import { CardProps, LinkCard, PostCard } from 'components/Card/types';
import { useAnyProvider } from 'hooks/Context';
import { useSessionStorage } from 'context/sessionStorage';

interface CarouselProps {
    data: any,
    titleCarousel: string,
    card: string,
    link?: boolean,
    hasFrom?: boolean,
    type: string,
};

const MobileCarouselProperties: React.FC<CarouselProps> = ({ card, hasFrom, data, titleCarousel, type, link }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const slideRef = useRef<HTMLDivElement>(null);
    const nextButtonRef = useRef<HTMLButtonElement>(null);
    const prevButtonRef = useRef<HTMLButtonElement>(null);
    const { saveToSessionStorage } = useSessionStorage()

    const { setSearchValue, setSearchType } = useAnyProvider()

    const navigate = useNavigate()

    const nextSlide = (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (currentIndex < data.length - 1) {
            setCurrentIndex((prev) => prev + 1);
        }
    };

    const prevSlide = (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        if (currentIndex > 0) {
            setCurrentIndex((prev) => prev - 1);
        }
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>, reg: string, refBuilding?: any) => {
        if (
            (nextButtonRef.current && nextButtonRef.current.contains(e.target as Node)) ||
            (prevButtonRef.current && prevButtonRef.current.contains(e.target as Node))
        ) {
            // O clique veio de um dos botões, não faça nada
            return;
        }
        if (type === 'default') {
            saveToSessionStorage('guest', 1)

            window.open(`/property/${reg}/${refBuilding}`, '_blank')
        } else if (type === 'unit') {
            setSearchValue(reg)
            saveToSessionStorage('guest', 1)

            setSearchType('name_building')
            navigate(`/properties?search=${reg}`)
        } else if (type === 'post') {
            saveToSessionStorage('guest', 1)
            window.location.href = `https://blog.yogha.com.br/${reg}`
        }
    };

    return (<S.Container>
        <S.CarouselContainer card={card} ref={slideRef}>
            {card === 'post' ? data.filter((item: any) => {
                return item.hasOwnProperty('title')
            }).map((image: PostCard, index: any) => {
                return (
                    <S.Slide
                        key={index}
                        active={index === currentIndex}
                        onClick={(e) => handleClick(e, image.slug as string)}
                    >
                        <Card.Post slug={image.slug} cardSelected={() => { }} key={image.id} title={image.title} id={image.id} jetpack_featured_media_url={image.jetpack_featured_media_url}
                        />
                    </S.Slide>
                )
            }) : data.filter((item: any) => item.hasOwnProperty('thumbnail')).map((props: CardProps, index: any) => {
                return (
                    <S.Slide
                        key={index}
                        active={index === currentIndex}
                        onClick={(e) => handleClick(e, card === 'building' ? props.title : props.accommodation_id as string, props.building_yogha)}
                    >
                        <Card.Default building_yogha={props.building_yogha} cardSelectedBuilding={() => { }} type={type} hasFrom={hasFrom} cardSelected={() => { }} buttonText=''
                            onNavigate={() => { }} accommodation_id={props.accommodation_id} cardType='imoveis' id={props.id} imageLink='' linkText='' key={props.accommodation_id} title={props.title} thumbnail={props.thumbnail} uniqueValue={props.uniqueValue}
                            address={{
                                city: props.address && props.address.city,
                                region: props.address && props.address.region,
                            }}
                            referencePoint={props.nearby_landmark !== null ? `${props.nearby_landmark} ${props.nearby_landmark_distance}` : null}

                        />
                    </S.Slide>
                )
            })}
            {link && data.slice(-1).map((props: LinkCard, index: any) => {
                return (
                    <S.Slide
                        key={index}
                        active={(index + data.length - 1) === currentIndex}
                        onClick={() => { }}
                    >
                        <Card.Link {...props} key={props.id}
                        />
                    </S.Slide>
                )
            })}
        </S.CarouselContainer>
        <S.ButtonContainer card={card}>
            <ButtonCarousel ref={prevButtonRef} disabled={currentIndex === 0 ? true : false} onClick={prevSlide} direction="left" />

            <ButtonCarousel ref={nextButtonRef} disabled={currentIndex === data.length - 1 ? true : false} onClick={nextSlide} direction="right" />
        </S.ButtonContainer>
    </S.Container>
    );
};

export default MobileCarouselProperties;
