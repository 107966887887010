import styled from 'styled-components';
import { FONT, FONT_SIZE, FONT_WEIGTH } from '../../constants/fontConstants';
import { NEUTRAL_COLORS, PRIMARY_COLORS } from '../../constants/styleConstants';
import {  LinkCard } from './types';

export const GlobalContainer = styled.div`
  display: flex;
  width: 310px;
  height: 341px;
  border-radius: 10px;
  margin-top: 16px;
  margin-left:-45px ;
  margin-right: 45px;
  word-wrap: break-word;

  cursor: pointer;

  @media (max-width: 768px) {
    display: flex;
    width: 100vw;
    height: unset;
    border-radius: unset;
    margin:0px;
    word-wrap: break-word;
  }

  `;

export const Container = styled.div`
  display: inline-flex;
  position: relative;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  border-radius: 10px;
&:hover {
    h1{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
  @media (max-width: 768px) {
    width: 100%;
    display: inline-flex;
    position: relative;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: unset;
  }

`;

export const Image = styled.img`
  width: 310px;
  height: 192px;
  margin-bottom: 16px;
  pointer-events: none;

  border-radius: 10px;

  background-color: ${NEUTRAL_COLORS.GRAY};

  object-fit: cover;

  @media (max-width: 768px) {
    width: 100%;
    height: 192px;
    margin-bottom: 16px;
    pointer-events: none;

    border-radius: 8px;

    background-color: ${NEUTRAL_COLORS.GRAY};

    object-fit: cover;
  }
`;

export const TextTitle = styled.h1`
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  font-size: ${FONT_SIZE.SMALL} ;
  font-weight: ${FONT_WEIGTH.HUGE};

`;

export const Infos = styled.div`
display: flex;
flex-direction: column;
row-gap:8px;
`;

export const TextOthers = styled.p`
  display: flex;
  gap: 4px;
  ${FONT.BODY.BODY_SMALL[400]}
`;

export const TextPrice = styled.p`
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  ${FONT.BODY.BODY_MEDIUM[600]}
`;

export const ImageWrapper = styled.div`
  -webkit-user-drag: none;
  -moz-user-drag: none;
  -ms-user-drag: none;
`;
export const SearchDescription = styled.p`
  display: flex;
  font-weight: ${FONT_WEIGTH.MEDIUM};
  font-size: ${FONT_SIZE.LITTLE};
  `;

export const AddressText = styled.p`
color: ${NEUTRAL_COLORS.DARK_GRAY};
font-size: ${FONT_SIZE.TINY};
font-weight: ${FONT_SIZE.SMALL};
height: 40px;
white-space: normal;
`;


export const TextFrom = styled.p`
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  ${FONT.CAPTION.CAPTION_SEMI_REGULAR};
`;


type SizeCard = Pick<LinkCard, 'size'>;

export const LinkContainer = styled.div<SizeCard>`
  display:flex;
  flex-direction:column;
  width:310px;
  height:${(props ) => props.size === 'unit' ? '338px' : props.size === 'post' ? '274px' : ''};
  justify-content: center;
  align-items: center;
  border:1px solid ${NEUTRAL_COLORS.GRAY};
  border-radius: 10px;
  row-gap:32px;

  @media (max-width: 768px) {
    width: 100%;
    height: 320px;
    height:${(props ) => props.size === 'unit' ? '310px' : props.size === 'post' ? '270px' : '320px'};
  }

`;


export const LinkText = styled.div`
width: 220px;
line-height: 1.4;
font-weight: ${FONT_WEIGTH.HUGE};
font-size: ${FONT_SIZE.SMALL};
color: ${NEUTRAL_COLORS.HIGH_DARK};
text-align: center;
word-wrap: break-word;
white-space: pre-wrap;
  `;

export const ScaleContainer = styled.div`
  display: flex;
  border-radius: 10px;
  position: relative;
  width: 50%;
  word-wrap: break-word;
  cursor: pointer;
  &:hover {
    h1{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
@media (max-width: 1439px) {
  width: min(100vw, 47%);
}
@media (min-width: 1440px) {
  width: min(100vw, 47%);
}
@media (min-width: 1920px) {
  width: min(100vw, 30.8%);
}
@media (min-width: 2560px) {
  width: min(100vw, 31%);
}
  `;

export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  border-radius: 10px;
  word-wrap: break-word !important;
  white-space: pre-wrap ;
  word-break: break-word !important;
    p{
      &:hover{
      color: ${PRIMARY_COLORS.MEDIUM};
    }
  }
`;

export const ScaleImage = styled.img`
  width: 100%;
  height: 219px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 16px;
  @media (max-width: 1439px) {

  height: 219px;
}
@media (min-width: 1440px) {

  height: 284px;
}
@media (min-width: 1920px) {

  height: 284px;
}
@media (min-width: 2560px) {

  height: 284px;
}
`;

export const Favorites = styled.div`
position: absolute;
margin-top: 8px;
margin-left: calc(100% - 48px);
`;

export const Gadget = styled.div<{header?:boolean}>`
display: flex;
position: absolute;
align-items: center;
justify-content: center;

width: 106px;
height: 29px;
margin-top: 8px;
margin-left: ${props => props.header ? '8px' : "calc(0% + 8px)"};

background: ${NEUTRAL_COLORS.WHITE};

border-radius: 4px;

white-space: nowrap;

p{
  color: ${NEUTRAL_COLORS.HIGH_DARK};
  font-size: ${FONT_SIZE.LITTLE};
  font-weight: ${FONT_WEIGTH.HUGE};
  &:hover{
    color: ${NEUTRAL_COLORS.HIGH_DARK};
  }
}

`;

export const ButtonContainer = styled.div`
    display: flex;

    justify-content: center;
    align-items: center;

    width: calc(100% - 32px);

    padding: 0px 16px;

    margin-top: 2px;
    margin-bottom: -20px;
`;