import * as S from './styles';
import { useCancellationPolicies } from './useCancellationPolicies';

interface CancellationPoliciesProps {
    date: string[] | null | Date[];
}

export const CancellationPolicies = ({ date }: CancellationPoliciesProps) => {

    const { cancelDate, dayDifference, question, tooltipOpen, setTooltipOpen } = useCancellationPolicies({ date });

    return (
        <S.CancelledSession>
            <S.ModalCancelled>
                <S.SubTitle>Políticas de cancelamento</S.SubTitle>
                <img
                    id="modal-cancelled"
                    onMouseOver={() => setTooltipOpen(true)}
                    onMouseLeave={() => setTooltipOpen(false)}
                    src={question}
                    alt=""
                />
                {tooltipOpen && (
                    <S.Tooltip id="tooltip">
                        <p>
                            {/* Tooltip content */}
                        </p>
                    </S.Tooltip>
                )}
            </S.ModalCancelled>
            <S.ScaledDate>
                {dayDifference > 6 ? (
                    <p>Cancelamento gratuito até o dia {cancelDate}.</p>
                ) : (
                    <p>Cancelamento gratuito não aplicável para as datas selecionadas.</p>
                )}
            </S.ScaledDate>
            <S.Terms>
                <p>
                    Saiba mais sobre as políticas de cancelamento acessando os nossos{' '}
                    <S.LinkTerms to={'/termos-e-condicoes/'}>termos de uso.</S.LinkTerms>
                </p>
            </S.Terms>
        </S.CancelledSession>
    );
};
