import * as S from './styles'
import { useDiscountCookie } from './useDiscountCupomCookie';
import usePaymentStore, { DataSessionPaymentProps } from './useStorage';
import { formatValue } from 'utils/formatValue';

interface RenderShortStayProps {
    discount: boolean;
    nights: number;
    dataPayment: DataSessionPaymentProps;
    expensesData: any;
    discountDifference: number;
    discountValue: number;
    hasCodePix: string | null;
    typePayment: string;
    totalValue: number;
    weekValue: number;
    difference: number;
}

//nights >= 27 &&
export const useRenderShortStay = ({ discount, dataPayment, expensesData, discountDifference, discountValue, hasCodePix, typePayment, difference, weekValue }: RenderShortStayProps) => {

    const { pixDiscountedTotalValue, pixDiscountedNightValue, recoverNightByPixCode, recoverTotalValueByPixCode, recoverTotalValueWithCupom } = usePaymentStore()

    const { discount: cupom, isDiscountValid } = useDiscountCookie()

    const cupomValue = cupom ? Number(cupom) : 0;


    const totalValueWithcupomValue = () => {
        const discountByTotal = dataPayment.total * (cupomValue / 100);
        const newTotalValue = (dataPayment.nightValue - discountByTotal) + dataPayment.cleaningValue;
        return newTotalValue;
    }

    const cupomTotalValue = totalValueWithcupomValue();

    const RenderShortStayCard = () => {
        return (
            <S.PaymentContainer>
                <S.Rows discount={discount}>
                    <p>{difference === 0 ? dataPayment?.difference : difference} Noites</p>
                    <S.ContainerValues discount={discount}>
                        {discount && <span>De</span>}
                        <S.ContainerPixDiscount discount={discount}>
                            <S.RowTotalValue>
                                <S.Prefix>R$</S.Prefix>
                                <S.RowValues >
                                    {hasCodePix ?
                                        (formatValue(recoverNightByPixCode())
                                        ) : expensesData.definedDailyValue.toFixed(0) === '0' && dataPayment?.nightValue ? (
                                            formatValue(pixDiscountedNightValue({ typePayment, hasCupom: isDiscountValid, cupomValue }))
                                        ) : (
                                            formatValue(pixDiscountedNightValue({ typePayment, hasCupom: isDiscountValid, cupomValue }))
                                        )}
                                </S.RowValues>
                            </S.RowTotalValue>

                        </S.ContainerPixDiscount>
                    </S.ContainerValues>
                </S.Rows>
                <S.Rows>
                    <p>{weekValue === 0 ? dataPayment?.weekValue : weekValue} Limpeza{weekValue > 1 || dataPayment && dataPayment?.weekValue > 1 && 's'}</p>
                    <S.ContainerValues>
                        <label>R$</label>
                        <S.RowValues>
                            {weekValue !== 0 ? formatValue(dataPayment?.cleaningValue) : dataPayment?.valueWeek}
                        </S.RowValues>
                    </S.ContainerValues>
                </S.Rows>
                <S.Rows>
                    <p>Pacote Yogha</p>
                    <S.ContainerValues include>
                        <label>incluso</label>
                    </S.ContainerValues>
                </S.Rows>
                <S.TotalRow>
                    <S.Total>Total</S.Total>
                    <S.ContainerPixDiscount>
                        <S.RowTotalValue >
                            <S.PrefixTotal hasDiscount={typePayment === 'PIX' || !!hasCodePix || cupomValue > 0} >R$</S.PrefixTotal>
                            <S.TotalValue hasDiscount={typePayment === 'PIX' || !!hasCodePix || cupomValue > 0}>
                                {hasCodePix === 'pixWithCupom' ? (
                                    formatValue(recoverTotalValueWithCupom(cupomValue))
                                ) : hasCodePix ? (
                                    formatValue(recoverTotalValueByPixCode())
                                ) :
                                    dataPayment.total === 0 ? formatValue(dataPayment.total) && formatValue(dataPayment.total)
                                        : formatValue(dataPayment.total)}
                            </S.TotalValue>
                        </S.RowTotalValue>
                        {((typePayment === 'PIX') || (cupomValue > 0)) && (
                            <S.RowTotalValue>
                                <S.PrefixTotal >R$</S.PrefixTotal>
                                <S.TotalValue>
                                    {hasCodePix ? formatValue(dataPayment.total) : cupomValue > 0 ? formatValue(cupomTotalValue) : pixDiscountedTotalValue(typePayment) === 0 ? formatValue(pixDiscountedTotalValue(typePayment)) : formatValue(pixDiscountedTotalValue(typePayment))}
                                </S.TotalValue>
                            </S.RowTotalValue>
                        )}
                    </S.ContainerPixDiscount>
                </S.TotalRow>
            </S.PaymentContainer>
        )

    }

    return { RenderShortStayCard }
}