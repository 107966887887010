import dayjs from "dayjs";
import { useDateDifference } from "hooks/useDateDifference";
import { useState } from "react";

export const useCancellationPolicies = ({ date }: { date: string[] | Date[] | null }) => {

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const today = dayjs().format('DD/MM/YYYY');
    const initialDate = date ? date[0] : null;

    const { dayDifference } = useDateDifference([today, initialDate! as string]);

    const cancelDate = dayjs(initialDate)?.subtract(7, 'day').format('DD/MM/YYYY');

    const question = require('assets/svg/spaceUser/Question.svg').default;

    return {
        tooltipOpen,
        dayDifference,
        cancelDate,
        question,
        setTooltipOpen
    }
}