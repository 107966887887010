import * as PS from "../styles";
import * as S from "./styles";
import { PostCard } from "../types";
import { Icons } from "../../Icons";
import { Button } from "../../Button";

export const Post: React.FC<PostCard & { cardSelected: any }> = ({ cardSelected, ...card }) => {

  const handleMouseDown = (e: React.MouseEvent) => {
    e.preventDefault();
    cardSelected(card.slug);
  };
  return (
    <PS.GlobalContainer>
      <S.PostContainer onMouseDown={handleMouseDown} titleButton={card.titleButton}>
        {card.titleButton ?
          <>
            <Icons icon={card.icon} width={80} height={80} />
            <S.LinkText>{card.buttonDescription}</S.LinkText>
            <PS.ButtonContainer>
              <Button widthSize={'100%'} onClickButton={() => { }} title={card.titleButton as string} icon={''} size='small' disabled={false} type='primary' />
            </PS.ButtonContainer>
          </> :
          <>
            <PS.Image src={card.jetpack_featured_media_url} />
            <S.TextPost>{card.title?.rendered}</S.TextPost>
          </>}

      </S.PostContainer>
    </PS.GlobalContainer>)

}