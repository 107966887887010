import { useEffect } from "react";
import { useDiscountCookie } from "./useDiscountCupomCookie";

export default function useListenerCupom() {
    const pathname = window.location.href;
    const { clearDiscount } = useDiscountCookie();

    const routeHasntDiscountInPayment = ['PIX_CODE', 'erro-de-pagamento', 'confirmado'].some((route) =>
        pathname.includes(route)
    );
    const routeHasntDiscount = !pathname.includes('payment');

    useEffect(() => {
        if (routeHasntDiscountInPayment || routeHasntDiscount) {
            clearDiscount();
        }
    }, [pathname, routeHasntDiscountInPayment, routeHasntDiscount, clearDiscount]);

    useEffect(() => {
        const handleCookieChange = () => {
            // Cookies changed, triggering update.
        };

        window.addEventListener('cookieChange', handleCookieChange);

        return () => {
            window.removeEventListener('cookieChange', handleCookieChange);
        };
    }, []);
}
