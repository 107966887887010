import { RangePickerRef } from "antd/es/date-picker/generatePicker/interface";
import { useAnyProvider } from "hooks/Context";
import { useSpaProvider } from "hooks/spaRouterContext";
import { RefObject, useCallback, useEffect, useRef, useState } from "react";
import {  useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import fetchAxios, { fetchAxiosAdmin } from "services/http";
import { mapBuilding } from "utils/formatterArray";
import { fetchAccommodations } from "services/accommodation";

 interface HeaderProps {
    paddingHeader?: boolean;
    getProperties?: any;
    setShouldFocus?: (arg0: boolean) => void
    shouldFocus?: boolean,
    setInputSearchValue?: any
  }

export const useHeader = ({setInputSearchValue,getProperties}:HeaderProps) => {
    const [units, setUnits] = useState<any[]>([]);
    const [promotionalUnits, setPromotinalUnits] = useState<any[]>([]);
    const [buildings, setBuildings] = useState<any[]>([]);

    const [mounted, setMounted] = useState(false);
    const [errorSearch, setErrorSearch] = useState(false);

    const headerRef = useRef<HTMLDivElement>(null);
    const calendarRef = useRef<RefObject<RangePickerRef<Date>>>(null);

    const [isOpen, setIsOpen] = useState(false);

    const { searchValue,  date } = useAnyProvider();

    const navigate = useNavigate()

    const { setExpanded, expanded, setPageChosen, pageChosen } = useSpaProvider();

    const { data, isError, isLoading, error, isFetching } = useQuery('fetchAccommodations', fetchAccommodations)

    const fetchBuilding = async () => {
      const { data } = await fetchAxiosAdmin.get(`buildings/list`,);
      return data
    }

    const { data: dataBuilding, isFetched, isLoading: isSuccessBuilding, error: errorBuilding } = useQuery('fetchBuildingData', fetchBuilding)

    const order = [
      "Helbor Stay Batel",
      "All You Need",
      "7Th Avenue Live",
      "Studio do Bosque",
      "CWBe",
      "Vivance Batel",
      "BFF2211",
      "Hyde Park",
      "Edifício Milena",
    ];

    useEffect(() => {
      setMounted(true);
      if (data) {
        setPromotinalUnits(data.promotionalUnits)
        setUnits(data.units)

      }
      if(dataBuilding && dataBuilding.length > 0){
        const sortedData = mapBuilding(dataBuilding).sort((a, b) => {
          const indexA = order.indexOf(a.title);
          const indexB = order.indexOf(b.title);
          // Se algum título não estiver na lista de ordem, mantenha-o no final
          if (indexA === -1) return 1;
          if (indexB === -1) return -1;
          return indexA - indexB;
        });
        const removePrincess = sortedData.filter((item) => item.title !== 'Edifício Princess')
        setBuildings(removePrincess);
      }

    }, [isFetched,dataBuilding,isFetching, isLoading]);

    const handleHeaderClick = () => {
      if (isOpen === true && !calendarRef?.current) {
        setIsOpen(false)
      } else {
        setIsOpen(true)
      }
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        headerRef.current &&
        !headerRef.current.contains(event.target as Node)
      ) {
        if (calendarRef?.current) {
          return;
        } else if (!calendarRef?.current) {
          return setExpanded(true);
        } else {
          setExpanded(true);
        }
      }
    };

    const handleCalendarClick = (event: any) => {
      event.stopPropagation();

    };

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
        setPageChosen(0)
      };
    }, [expanded]);

    const globalContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      // Função para lidar com o clique fora
      const handleClickOutside = (event: MouseEvent) => {
        const targetElement = event.target as HTMLElement;
        if (
          globalContainerRef.current &&
          !globalContainerRef.current.contains(targetElement) &&
          !targetElement.closest('.ant-picker-dropdown')
        ) {
          setExpanded(true);
        }
      };

      // Adicionar o ouvinte de eventos ao documento
      document.addEventListener('mousedown', handleClickOutside);

      // Limpar o ouvinte quando o componente for desmontado
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    // const invalidSearch = (): boolean => {
    //   if (expanded === true) {
    //     return false
    //   }
    //   if (expanded === false) {
    //     if (searchValue !== '' && anyValue !== 'false') {
    //       return false
    //     }
    //   }
    //   return true
    // }

    const array = [
      { label: '1  Hóspede', value: '1' },
      { label: '2  Hóspedes', value: '2' },
      { label: '3  Hóspedes', value: '3' },
      { label: '4  Hóspedes', value: '4' },
      { label: '5  Hóspedes', value: '5' },
      { label: '6  Hóspedes', value: '6' },
      { label: '7  Hóspedes', value: '7' },
      { label: '8  Hóspedes', value: '8' },
      { label: '9  Hóspedes', value: '9' },
    ]

    function handleTitles(): string {
      switch (pageChosen) {
        case 0:
          return 'Apês em destaque'
        case 1:
          return 'Oportunidades para você'
        case 3:
          return 'Cidades mais procuradas'
        case 2:
          return 'Sugestões de unidades Yogha'
        default:
          return 'Em destaque'
      }
    }

    const carouselTitle = handleTitles()


    const address = [
      { label: 'Helbor Stay Batel, Av. Silva Jardi...', value: 1 },
      { label: 'All You Need - R. Riachuelo, 110...', value: 2 },
      { label: '7th Avenue Oxford - R. Conselhe...', value: 3 },
      { label: 'São Paulo - SP', value: 4 },
      { label: 'Curitiba', value: 5 },
    ]

    const closeCalendar = () => {
      if (date === null && calendarRef.current !== null) {
        //@ts-ignore
        calendarRef.current?.blur()
      } else {
        //@ts-ignore
      }
    }
    const handleClickButton = useCallback((text: string) => {
      if (text !== '') {
        setErrorSearch(false)

        setExpanded(true)
        navigate(`/properties?search=${searchValue}`)
        if (getProperties)
          getProperties()
      } else {
        setErrorSearch(true)
      }
      //@ts-ignore
      setTimeout(() => {
        setErrorSearch(false)
      }, 3000);

    }, [getProperties])


    return {
        mounted,
        closeCalendar,
        globalContainerRef,
        errorSearch,
        handleHeaderClick,
        calendarRef,
        array,
        handleClickButton,
        isFetching,
        isLoading,
        carouselTitle,
        units,
        promotionalUnits,
        buildings,
        isOpen,
        setIsOpen,
        handleCalendarClick,
        address,
        setErrorSearch
    }
}