import { BrowserRouter } from "react-router-dom";
import { Routers } from "./routes/Router";
import { UserProvider } from "./hooks/userContext";
import { PaymentProvider } from "context/paymentContext";
import { QueryClient, QueryClientProvider } from 'react-query'
import { SkeletonTheme } from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import { HistoryProvider } from "hooks/useHistory";
import useLanguage from "hooks/useLanguage";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { PaymentTypeProvider } from "pages/Payment/hooks/usePaymentType";
import { HelmetProvider } from "react-helmet-async";
import useListenerCupom from "pages/Payment/components/PaymentCard/useListenerCupom";

dayjs.extend(customParseFormat);

const queryClient = new QueryClient()


function App() {
  useLanguage()
  useListenerCupom()

  return (

    <QueryClientProvider client={queryClient} >

      <HistoryProvider>
        <SkeletonTheme baseColor="#EBEAEA" highlightColor="#d3d3d3" enableAnimation>
          <UserProvider>
            <PaymentTypeProvider >
              <PaymentProvider>
                <BrowserRouter>
                  <Routers />
                </BrowserRouter>
              </PaymentProvider>
            </PaymentTypeProvider>
          </UserProvider>
        </SkeletonTheme>
      </HistoryProvider>
    </QueryClientProvider >
  );
}

export default App;
