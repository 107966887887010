import { z } from 'zod';

const currentYear = new Date().getFullYear();
const currentYearShort = currentYear % 100;

export const creditCardSchema = z.object({
  number: z
    .string({required_error: 'Campo obrigatório'})
    .min(16, 'Campo obrigatório'),
  name: z.string({required_error: 'Campo obrigatório'}).min(1, 'Campo obrigatório'),
  expiry: z
  .string({required_error: 'Campo obrigatório'})
    .min(4, 'Campo obrigatório')
    .regex(/^(0[1-9]|1[0-2])\/?([0-9]{2})$/, 'Data de validade inválida')
    .refine(
      (expiry) => {
        const  year = expiry.slice(-2);
        return Number(year)  >= currentYearShort;
      },
      {
        message: `Ano de cartão expirado`,
      }
    ),
  cvc: z
  .string({required_error: 'Campo obrigatório'})
    .min(3, 'Campo obrigatório'),
  document: z
    .string({required_error: 'Campo obrigatório'})
    .min(11, 'Campo obrigatório')
    .max(14, 'O documento deve ter no máximo 14 dígitos')
    .regex(/^\d{11,14}$/, 'Documento inválido'),
});

export const addressSchema = z.object({
    zipCode: z
      .string({required_error: 'Campo obrigatório'})
      .min(8, 'Campo obrigatório'),
    streetNumber: z
      .string({required_error: 'Campo obrigatório'})
      .min(1, 'Campo obrigatório')
      .regex(/^\d+$/, 'Número inválido'),
    neighborhood: z.string({required_error: 'Campo obrigatório'}).min(1, 'Campo obrigatório'),
    street: z.string({required_error: 'Campo obrigatório'}).min(1, 'Campo obrigatório'),
    complement: z.string({required_error: 'Campo obrigatório'}).optional(),
    city: z.string({required_error: 'Campo obrigatório'}).min(1, 'Campo obrigatório'),
  });
