import { FONT } from "constants/fontConstants";
import { NEUTRAL_COLORS, PRIMARY_COLORS } from "constants/styleConstants";
import styled, { css } from "styled-components";

export const Container = styled.div<Pick<GuestSelectorStylesProps, 'fill'>>`
    display: flex;
    position: relative;

    ${({fill})=> fill && css`
    width: calc(100% + 32px);
    `}
`;


type GuestSelectorStylesProps = {
    isOpen: boolean;
    fill?: boolean;
    isCard?: boolean;
}

export const ContentContainer = styled.div<GuestSelectorStylesProps>`
    display: flex;

    width: calc(270px - 32px);

    justify-content: space-between;

    padding: 16px;

    border: 1px solid ${NEUTRAL_COLORS.GRAY};
    border-radius: 10px;

    input{
        border: none;
        outline: none;
        background-color: transparent;
        ${FONT.BODY.BODY_SMALL[400]}
        color: ${NEUTRAL_COLORS.HIGH_DARK};
    }

    ${({isOpen})=> isOpen && css`
    border-color: ${PRIMARY_COLORS.MEDIUM};
        img{
            rotate: 180deg;
        }
    `}

    ${({fill})=> fill && css`
        width: 100%;
    `}

    ${({isCard})=> isCard && css`
        border: none;
    `}

`;

export const ModalGuests = styled.div<GuestSelectorStylesProps>`
    position: absolute;
    top: 60px;
    left: 0;
    background: ${NEUTRAL_COLORS.WHITE};
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    z-index: 10;
    box-shadow: 0 8px 35px rgba(0, 0, 0, 0.2);

    width: calc(315px - 32px);

    ${({fill})=> fill && css`
        width: calc(100% - 32px);
    `}

    ${({isCard}) => isCard && css`
        left: -16px;
        width: 100%;
    `}
`;

export const GuestOption = styled.div`
    display: flex;

    align-items: center;
    justify-content: space-between;

    width: 100%;
`;

export const GuestOptionColumnDetails = styled.div`
    display: flex;
    flex-direction: column;

    gap: 4px;

    strong{
        ${FONT.BODY.BODY_SMALL[500]}
        color: ${NEUTRAL_COLORS.HIGH_DARK}
    }
    p{
        ${FONT.BODY.BODY_SMALL[400]}
        color: ${NEUTRAL_COLORS.DARK_GRAY}
    }
`;

export const GuestOptionColumnButtons = styled.div`
    display: flex;

    width: 106px;
    align-items: center;
    justify-content: space-between;
`;

export const GuestsButton = styled.button`
    display: flex;

    align-items: center;
    justify-content: center;

    width: 32px;
    height: 32px;

    background: ${NEUTRAL_COLORS.WHITE};
    border-radius: 8px;
    border: none;
    cursor: pointer;

    border: 2px solid ${NEUTRAL_COLORS.GRAY};

    :disabled{
        border : 2px solid ${NEUTRAL_COLORS.LIGHT_GRAY};
    }
`;

export const Divisor = styled.div`
    width: 100%;
    height: 1px;
    background: ${NEUTRAL_COLORS.LIGHT_GRAY};
    margin: 16px 0;
`;