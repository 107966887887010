import { useState, useEffect } from 'react';
import * as S from './styles';
import { ButtonCarousel } from '../ButtonCarousel';
import { linkImage } from 'utils/formatterArray';
import { useDateDifference } from 'hooks/useDateDifference';
import { useScreenWidth } from 'utils/useScreenWidth';
import { cancelRedirectionLink } from 'utils/cancelRedirectionLink';
import { useSearchParams } from 'react-router-dom';
import { useAnyProvider } from 'hooks/Context';
import dayjs from 'dayjs';

interface ICardResult {
  images: any[];
  width?: string;
}

type Result = {
  nameAd: string;
  region: string;
  city: string;
  bedrooms: number;
  meters: number;
  price: number;
  gadget?: string | boolean;
  maxGuests: number;
  acceptsPets: boolean;
  id: string;
  mainImage?: string;
  iptu?: number;
  condominium?: number;
  sumMonth: string | number;
  midStay: string | null | number;
  building_yogha: string;
  cleaningFee?: number;
  totalWithTax?: boolean;
  mediaByNight?: number;
  yogha_pack: number
};

export type CardResultProps = Result & ICardResult;

export const CardResult: React.FC<CardResultProps & { date: any }> = ({ date, ...card }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const [mouseButton, setMouseButton] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const { dayDifference, weeklyDifference } = useDateDifference(date);

  const { setDate } = useAnyProvider()

  const breakpoint = useScreenWidth();

  const [currentImage, setCurrentImage] = useState('');

  // Atualiza a imagem atual baseada no currentIndex
  useEffect(() => {
    if (card.images.length > 0) {
      const nextImage = card.mainImage && card.images.length > 1
        ? linkImage + card.images[currentIndex].image_path
        : `https://d6ufmjskn3t3w.cloudfront.net/fit-in/800x800/filters:quality(65)/${card.mainImage}`;
      setCurrentImage(nextImage);
    }
  }, [currentIndex, card.images, card.mainImage]);


  useEffect(() => {
    card.images.forEach((image) => {
      const img = new Image();
      img.src = card.mainImage && card.images.length > 1 ? linkImage + image.image_path : `https://d6ufmjskn3t3w.cloudfront.net/fit-in/800x800/filters:quality(65)/${card.mainImage}`;
    });
  }, [card.images, card.mainImage]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + card.images.length) % card.images.length);
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % card.images.length);
  };

  useEffect(() => {
    if (breakpoint) {
      setIsHover(true)
    }
  }, [breakpoint])

  const id = card.nameAd.slice(-6)

  const handleOpenProperty = () => {
    if (mouseButton) {
      return;
    } else {
      const url = `/h/${id}`;
      const checkIn = searchParams.get('check_in');
      const checkOut = searchParams.get('check_out');
      if (checkIn && checkOut) {
        setDate([dayjs(checkIn).toDate(), dayjs(checkOut).toDate()])
      }
      // const url = `/property/${card.id}/${card.building_yogha}`;
      window.open(url, '_blank');
    }
  };

  return (
    <a href={`/h/${id}`} onClick={(e) => cancelRedirectionLink(e as any)} style={{
      textDecoration: 'none',
      color: 'unset',
    }} >
      <S.ScaleContainer
        width={card.width}
        onClick={handleOpenProperty}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        <S.SearchContainer>
          {card.gadget && (
            <S.Gadget>
              <p>{card.gadget}</p>
            </S.Gadget>
          )}
          {
            //<S.Favorites>
            //<ButtonFav />
            //</S.Favorites>
          }
          <S.ScaleImage
            key={currentImage} // A chave aqui garante que o componente será tratado como um novo elemento quando a imagem mudar
            initial={{ opacity: 0.75 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.35 }}
            src={currentImage}
          />
          <S.CollumnInfo>
            <S.Infos>
              <S.TextTitle>{card.nameAd}</S.TextTitle>
              <S.AddressText>
                {card.region} - {card.city}{' '}
              </S.AddressText>
            </S.Infos>
            {dayDifference <= 1 ? (
              <S.Infos>
                <S.RowComplementDefault>
                  <S.TextComplement>{card.bedrooms} quarto(s)</S.TextComplement>
                  <S.TextComplement>
                    {' '}
                    {card.meters}m<sub>2</sub>
                  </S.TextComplement>
                  <S.TextComplement>{card.maxGuests} pessoa(s)</S.TextComplement>
                  <S.TextComplement> Não aceita pets</S.TextComplement>
                </S.RowComplementDefault>
                {card.totalWithTax ? <S.TextOthers>
                  A partir de <S.TextPrice>{`R$ ${card.price && card.cleaningFee && (card.price + card.cleaningFee).toLocaleString('pt-BR', {
                    maximumFractionDigits: 0,
                  })},00 `}</S.TextPrice>
                  {'noite'}
                </S.TextOthers> : <S.TextOthers>
                  A partir de <S.TextPrice>{`R$ ${card.price && card.price.toFixed(0)},00 `}</S.TextPrice>
                  {'noite'}
                </S.TextOthers>}
              </S.Infos>
            ) :
              dayDifference < 27 && dayDifference > 1 ? (
                <S.Infos>
                  <S.RowComplementDefault>
                    <S.TextComplement>{card.bedrooms} quarto(s)</S.TextComplement>
                    <S.TextComplement>
                      {' '}
                      {card.meters}m<sub>2</sub>
                    </S.TextComplement>
                    <S.TextComplement>{card.maxGuests} pessoa(s)</S.TextComplement>
                    <S.TextComplement> Não aceita pets</S.TextComplement>
                  </S.RowComplementDefault>
                  {card.totalWithTax ? (
                    <S.TextOthers>
                      <S.TextPrice>{`R$ ${card.mediaByNight && card.cleaningFee && ((card.mediaByNight * dayDifference) + (card.cleaningFee * weeklyDifference)).toFixed(0)},00`}</S.TextPrice>
                      <S.TotalWithDateDefined colorText='black' > Total com taxas inclusas</S.TotalWithDateDefined>
                    </S.TextOthers>
                  ) : (
                    <S.TextOthers>
                      <S.TextPrice>{`R$ ${card.price && card.mediaByNight && card.mediaByNight.toFixed(0)},00 `}</S.TextPrice>
                      <b>noite </b>
                      <S.TotalWithDateDefined colorText='gray' bullet > Total de R$ {(card.mediaByNight && card.mediaByNight * dayDifference)?.toFixed(0)},00 </S.TotalWithDateDefined>
                    </S.TextOthers>
                  )}
                </S.Infos>
              ) :
                (
                  <S.Infos>
                    <S.RowComplementDefault>
                      <S.TextComplement>{card.bedrooms} quarto(s)</S.TextComplement>
                      <S.TextComplement>
                        {' '}
                        {card.meters}m<sub>2</sub>
                      </S.TextComplement>
                      <S.TextComplement>{card.maxGuests} pessoa(s)</S.TextComplement>
                      <S.TextComplement> Não aceita pets</S.TextComplement>
                    </S.RowComplementDefault>
                    <S.TextOthers>
                      {card.totalWithTax && card.cleaningFee ? (
                        <>
                          <S.TextPrice>
                            {card.midStay !== null
                              ? ((card.cleaningFee * weeklyDifference) + Math.floor(Number(card.condominium)) + Math.floor(Number(card.yogha_pack)) + Math.floor(Number(card.iptu)) + parseFloat(card.midStay as string)).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                                maximumSignificantDigits: 4

                              })
                              : ((card.cleaningFee * weeklyDifference) + Math.floor(Number(card.condominium)) + Math.floor(Number(card.yogha_pack)) + Math.floor(Number(card.iptu)) + parseFloat(card.sumMonth as string)).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                                maximumSignificantDigits: 4
                              })},00
                          </S.TextPrice>
                          Total mensal
                        </>
                      ) : (
                        <>
                          <S.TextPrice>
                            {card.midStay !== null
                              ? parseFloat(card.midStay as string).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                                maximumFractionDigits: 0
                              })
                              : parseFloat(card.sumMonth as string).toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                                maximumFractionDigits: 0
                              })},00
                          </S.TextPrice>
                          Mês
                        </>
                      )}

                    </S.TextOthers>
                  </S.Infos>
                )}
          </S.CollumnInfo>

          <S.ButtonContainer hover={isHover}>
            <ButtonCarousel
              onMouseLeave={() => setMouseButton(false)}
              onMouseDown={() => setMouseButton(true)}
              size={40}
              iconSize={22}
              onClick={handlePrevClick}
              direction="left"
            />
            <ButtonCarousel
              onMouseLeave={() => setMouseButton(false)}
              onMouseDown={() => setMouseButton(true)}
              size={40}
              iconSize={22}
              onClick={handleNextClick}
              direction="right"
            />
          </S.ButtonContainer>
        </S.SearchContainer>
        <S.BulletContainer>
          {card.images.map((_: any, i: number) => {
            return <S.Bullet key={i} onClick={() => setCurrentIndex(i)} active={currentIndex === i ? true : false} />;
          })}
        </S.BulletContainer>
      </S.ScaleContainer>
    </a>
  );
};
