import { NeutralColorsType, PrimaryColorsType, StatusColorsType } from '../../constants/styleConstants'

type IconWarningProps = {
    size?: number
    color?: StatusColorsType | PrimaryColorsType | NeutralColorsType
}

export const Warning = ({ color = '#51B755', size = 20 }: IconWarningProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size * 2} height={size} viewBox="0 0 20 20" fill="none">
            <path d="M11.375 14.875C11.375 15.0975 11.309 15.315 11.1854 15.5C11.0618 15.685 10.8861 15.8292 10.6805 15.9144C10.475 15.9995 10.2488 16.0218 10.0305 15.9784C9.8123 15.935 9.61184 15.8278 9.45451 15.6705C9.29717 15.5132 9.19003 15.3127 9.14662 15.0945C9.10321 14.8762 9.12549 14.65 9.21064 14.4445C9.29579 14.2389 9.43998 14.0632 9.62499 13.9396C9.80999 13.816 10.0275 13.75 10.25 13.75C10.5484 13.75 10.8345 13.8685 11.0455 14.0795C11.2565 14.2905 11.375 14.5766 11.375 14.875ZM20 10C20 11.9284 19.4282 13.8134 18.3568 15.4168C17.2855 17.0202 15.7627 18.2699 13.9812 19.0078C12.1996 19.7458 10.2392 19.9389 8.34787 19.5627C6.45656 19.1865 4.71927 18.2579 3.35571 16.8943C1.99215 15.5307 1.06355 13.7934 0.687348 11.9021C0.311142 10.0108 0.504224 8.05042 1.24218 6.26884C1.98013 4.48726 3.22982 2.96452 4.83319 1.89317C6.43657 0.821828 8.32164 0.25 10.25 0.25C12.835 0.25273 15.3134 1.28084 17.1413 3.10872C18.9692 4.93661 19.9973 7.41498 20 10ZM18.5 10C18.5 8.3683 18.0161 6.77325 17.1096 5.41655C16.2031 4.05984 14.9146 3.00242 13.4071 2.37799C11.8997 1.75357 10.2409 1.59019 8.64051 1.90852C7.04017 2.22685 5.57016 3.01259 4.41637 4.16637C3.26259 5.32015 2.47685 6.79016 2.15853 8.3905C1.8402 9.99085 2.00358 11.6496 2.628 13.1571C3.25242 14.6646 4.30984 15.9531 5.66655 16.8596C7.02326 17.7661 8.61831 18.25 10.25 18.25C12.4373 18.2475 14.5343 17.3775 16.0809 15.8309C17.6275 14.2843 18.4975 12.1873 18.5 10Z" fill={color} />
            <path d="M10.23 12.0594C9.80649 12.0594 9.45952 11.723 9.44633 11.2997L9.27571 5.82492C9.26165 5.37355 9.62373 5 10.0753 5H10.3847C10.8363 5 11.1984 5.37355 11.1843 5.82492L11.0137 11.2997C11.0005 11.723 10.6535 12.0594 10.23 12.0594Z" fill={color} />
        </svg>
    )
}