
export const sectionsProprietary =
    {
      headerText: 'Para proprietários',
      title:'Potencialize a sua rentabilidade com o Yogha Gestão',
      text:'Yogha Gestão garante aos nossos parceiros proprietários uma administração completa e inteligente do seu imóvel, visando a máxima rentabilidade, de forma ágil e transparente.',
      image: require('assets/image/proprietary.webp'),
      buttonText:'Conheça',
    }

export const sectionsIncorporators =
        {
            headerText: 'Para parceiros',
            title:'Você tem o projeto e a Yogha, a expertise',
            text:'A Yogha conecta o seu imóvel a serviços, marcas e tecnologias que o ajudarão a maximizar os seus resultados. Conheça as soluções personalizadas para o seu prédio.',
            image: require('assets/image/incorporators.webp'),
            buttonText:'Saiba mais',
          }

export const citys = (id:any) => {

  return [
            {idAccommodation:id,
            describePost:'Curitiba',
            image:require('assets/image/citys/Curitiba.png'),
            cardType:'posts' },

            {idAccommodation:id,
            describePost:'Florianopolis',
            image:require('assets/image/citys/Florianopolis.png'),
            cardType:'posts' },

            {idAccommodation:id,
            describePost:'São Paulo',
            image:require('assets/image/citys/SaoPaulo.png'),
            cardType:'posts' },

            {idAccommodation:id,
            describePost:'Rio de Janeiro',
            image:require('assets/image/citys/RioDeJaneiro.png'),
            cardType:'posts' },

          ]}

export const oportunitys= (id:any) =>  {
  return [
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN055',
              image: require('assets/image/oportunitys/oportunity1.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '158',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN045',
              image: require('assets/image/oportunitys/oportunity2.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '146',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('assets/image/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('assets/image/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN055',
              image: require('assets/image/oportunitys/oportunity1.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '158',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            { id:id,
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN045',
              image: require('assets/image/oportunitys/oportunity2.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '146',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('assets/image/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('assets/image/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('assets/image/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('assets/image/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
            },
          ]};

export const oportunitysWithGadget= (id:any) => {
  return [
            {  idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN055',
              image: require('assets/image/oportunitys/oportunity1.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '158',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba',
              gadget:'Baixou o preço',
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN045',
              image: require('assets/image/oportunitys/oportunity2.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '146',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba',
              gadget:'Baixou o preço'
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('assets/image/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba',
              gadget:'Baixou o preço'
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('assets/image/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba',
              gadget:'Baixou o preço',
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN055',
              image: require('assets/image/oportunitys/oportunity1.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '158',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba',
              gadget:'Baixou o preço',
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN045',
              image: require('assets/image/oportunitys/oportunity2.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '146',
              description: 'IPTU e Condomínio R$ 0,00',
              gadget:'Baixou o preço',
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('assets/image/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
              gadget:'Baixou o preço'
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('assets/image/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
              gadget:'Baixou o preço',
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('assets/image/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
              gadget:'Baixou o preço'
            },
            { idAccommodation:id,
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('assets/image/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
              gadget:'Baixou o preço'
            },
          ]};



export const oportunitysWithLink= [
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN055',
              image: require('assets/image/oportunitys/oportunity1.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '158',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba',
              onClick: () => {}
            },
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN045',
              image: require('assets/image/oportunitys/oportunity2.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '146',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('assets/image/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('assets/image/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN055',
              image: require('assets/image/oportunitys/oportunity1.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '158',
              description: 'IPTU e Condomínio R$ 0,00',
              city:'Curitiba'
            },
            {
              cardType:'imoveis',
              title: 'Rua Riachuelo, 110 - AYN045',
              image: require('assets/image/oportunitys/oportunity2.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '146',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('assets/image/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              cardType:'imoveis',
              title: 'Rua Amintas de Barros, 240 - HUBN001',
              image: require('assets/image/oportunitys/oportunity4.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '154',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              cardType:'imoveis',
              title: 'Av. Silva Jardim, 2424 - HSB007',
              image: require('assets/image/oportunitys/oportunity3.png'),
              location: 'Centro - Curitiba, PR',
              address: '',
              price: '199',
              description: 'IPTU e Condomínio R$ 0,00',
            },
            {
              imageLink:require('assets/svg/Percent.svg').default,
              cardType:'link',
              buttonText:'Ver ofertas',
              linkText:"Conheça todas as nossas ofertas",
              sizeCard:'imoveis'
             },
          ];

