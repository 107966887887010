import { Button } from 'components/Button'
import * as S from './styles'
import { HeaderAlt } from 'components/HeaderAlt'
import { Footer } from 'components/Footer'
import { useNavigate } from 'react-router-dom'
import useListenerCupom from 'pages/Payment/components/PaymentCard/useListenerCupom'

export const FailedPayment = () => {

  document.title = "Erro de pagamento | Yogha";

  const navigate = useNavigate();

  useListenerCupom()

  const Icon = () => <svg
    className="warning-circle"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM16 27C13.8244 27 11.6977 26.3549 9.88873 25.1462C8.07979 23.9375 6.66989 22.2195 5.83733 20.2095C5.00477 18.1995 4.78693 15.9878 5.21137 13.854C5.63581 11.7202 6.68345 9.7602 8.22183 8.22183C9.76021 6.68345 11.7202 5.6358 13.854 5.21136C15.9878 4.78692 18.1995 5.00476 20.2095 5.83733C22.2195 6.66989 23.9375 8.07979 25.1462 9.88873C26.3549 11.6977 27 13.8244 27 16C26.9967 18.9164 25.8367 21.7123 23.7745 23.7745C21.7123 25.8367 18.9164 26.9967 16 27ZM15 17V10C15 9.73478 15.1054 9.48043 15.2929 9.29289C15.4804 9.10536 15.7348 9 16 9C16.2652 9 16.5196 9.10536 16.7071 9.29289C16.8946 9.48043 17 9.73478 17 10V17C17 17.2652 16.8946 17.5196 16.7071 17.7071C16.5196 17.8946 16.2652 18 16 18C15.7348 18 15.4804 17.8946 15.2929 17.7071C15.1054 17.5196 15 17.2652 15 17ZM17.5 21.5C17.5 21.7967 17.412 22.0867 17.2472 22.3334C17.0824 22.58 16.8481 22.7723 16.574 22.8858C16.2999 22.9993 15.9983 23.0291 15.7074 22.9712C15.4164 22.9133 15.1491 22.7704 14.9393 22.5607C14.7296 22.3509 14.5867 22.0836 14.5288 21.7926C14.4709 21.5017 14.5007 21.2001 14.6142 20.926C14.7277 20.6519 14.92 20.4176 15.1667 20.2528C15.4133 20.088 15.7033 20 16 20C16.3978 20 16.7794 20.158 17.0607 20.4393C17.342 20.7206 17.5 21.1022 17.5 21.5Z"
      fill="white"
    />
  </svg>

  return (
    <S.ContainerPage>
      <HeaderAlt />
      <S.Container>
        <S.Content>
          <S.Header>
            <Icon />
            <h1>Não foi possível processar o pagamento</h1>
          </S.Header>

          <S.ContainerInformation>
            <p>Desculpe, ocorreu um erro no processamento do seu pagamento. Isso pode ter sido causado por uma série de motivos. Tente seguir as instruções abaixo para completar a sua reserva:
            </p>
            <ol>
              <li>Verifique se os seus cadastrais estão corretos e tente reservar novamente. </li>
              <li>Caso as suas informações cadastrais estejam corretas, aguarde alguns instantes e tente novamente realizar a reserva. O problema pode ter sido causado por uma instabilidade em nosso sistema. </li>
              <li>Se mesmo assim as dificuldades persistirem, por favor entre em contato com o nosso Suporte, que estará disponível 24/7 para ajudá-lo(a).</li>
            </ol>
          </S.ContainerInformation>
          <S.ButtonContainer>
            <Button onClickButton={() => navigate(-3)} title='Tentar novamente' size='large' type='primary' />
            <Button onClickButton={() => navigate('/')} title='Voltar a página inicial' size='large' type='secondary' />
          </S.ButtonContainer>
        </S.Content>
      </S.Container>
      <Footer />
    </S.ContainerPage>
  )
}