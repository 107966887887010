import { create } from "zustand";
import { persist } from "zustand/middleware";

interface GuestState {
  adults: number;
  children: number;
  babies: number;
  totalGuests: number;
  isOpen: boolean;
  updateAdults: (amount: number) => void;
  updateChildren: (amount: number) => void;
  updateBabies: (amount: number) => void;
  calculateTotalGuests: () => void;
  openModal: () => void;
  closeModal: () => void;
}

export const useGuestStore = create(
  persist<GuestState>(
    (set) => ({
      adults: 1,
      children: 0,
      babies: 0,
      totalGuests: 1, // Inicialmente igual a adultos
      isOpen: false,
      updateAdults: (amount) =>
        set((state) => {
          const updatedAdults = Math.max(state.adults + amount, 1); // Garante que adultos >= 1
          return {
            adults: updatedAdults,
            totalGuests: updatedAdults + state.children,
          };
        }),
      updateChildren: (amount) =>
        set((state) => {
          const updatedChildren = Math.max(Math.min(state.children + amount, 5), 0); // Limite de 0 a 5 crianças
          return {
            children: updatedChildren,
            totalGuests: state.adults + updatedChildren,
          };
        }),
      updateBabies: (amount) =>
        set((state) => {
          const updatedBabies = Math.max(Math.min(state.babies + amount, 2), 0); // Limite de 0 a 2 bebês
          return {
            babies: updatedBabies,
          };
        }),
      calculateTotalGuests: () =>
        set((state) => ({
          totalGuests: state.adults + state.children,
        })),
      openModal: () =>
        set(() => ({
          isOpen: true,
        })),
      closeModal: () =>
        set(() => ({
          isOpen: false,
        })),
    }),
    {
      name: "guest-storage", // Nome para o session storage
      getStorage: () => sessionStorage, // Altera para sessionStorage
    }
  )
);
